export const PERFORM_LOGIN = "PERFORM_LOGIN";


export const ADD_TOAST = "ADD_TOAST";
export const DEL_TOAST = "DEL_TOAST";
export const DEL_MULTIPLE_TOAST = "DEL_MULTIPLE_TOAST";

export const CREATE_REGISTRATION = "CREATE_REGISTRATION";

export const SET_ONLINE = "SET_ONLINE";
export const SET_OFFLINE = "SET_OFFLINE";
