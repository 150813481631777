
import store from '../store';
import { reducers_map, evento_all, categoria_all, partecipanti_all, thread_all, allegato_all, categoriaevento_all } from '../reducers'
import { Esportone } from 'django-rest-react'
import { sentry_log } from '../utils'
import { SET_OFFLINE, SET_ONLINE } from '../actions/action-types';

export interface WorkerEvent extends Event {
  data: string
}


export interface CachedMessage {
  origin: string,
  json: {
    results: any
  }
}


const cached_response_handler = (message: CachedMessage) => {
  const url = new URL(message.origin);
  let page = null;
  // @ts-ignore
  for (const [key, value] of url.searchParams) {
    if (key === "page") {
      page = value;
      break;
    }
  }
  const callback = (cusu: Esportone<any>) => cusu.update_object_action(message.json.results);

  let found = false;
  for (const [name, esportone] of Object.entries(reducers_map)) {
    if (esportone.url() === url.pathname) {
      store.dispatch(callback(esportone as Esportone<any>));
      found = true;
      break;
    }
  }
  if (!found) {
    sentry_log(new Error(`Ricevuto messaggio di cache dal sw con pathname sbagliato: ${url}`))
  }
}

interface NetworkStatusMessage {
  online: boolean,
}

const network_status_handler = (message: NetworkStatusMessage) => {
  if (message.online) {
    store.dispatch({ type: SET_ONLINE })
  } else {
    store.dispatch({ type: SET_OFFLINE })
  }
}


export const service_worker_handler = (event: WorkerEvent) => {
  const message = JSON.parse(event.data);
  switch (message.header) {
    case "cached_response":
      return cached_response_handler(message.body);
    case "network_status":
      return network_status_handler(message.body);
    default:
      console.warn("Received wrong header from service worker", message.header);
      break;
  }
  /* event.ports[0].postMessage("Client 1 Says 'Hello back!'"); */
}
