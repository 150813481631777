import { combineReducers } from 'redux';
import { useSelector } from 'react-redux'

import {
  loadingReducer, LoadingState,
  tentativoReducer, TentativoState
} from 'django-rest-react';
import { reducers_veri } from './cache';
import { toastReducer } from './toast';
import { webpushReducer } from './webpush';
import { loginReducer } from './login';
import { onlineReducer } from './online';




const rootReducer = combineReducers({
  login: loginReducer,
  loading: loadingReducer,
  tentativi: tentativoReducer,
  toasts: toastReducer,
  webpush: webpushReducer,
  online: onlineReducer,
  ...reducers_veri
});


export {
  evento_all, categoria_all, partecipanti_all, shortlist_all
} from './cache';


export type AppState = ReturnType<typeof rootReducer>;
export const useLogin = () => useSelector((state: AppState) => state.login);
export const useOnline = () => useSelector((state: AppState) => state.online);
export default rootReducer;
export * from './cache'
export * from './toast'
