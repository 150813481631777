import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { build_tex_problema_post } from '../api';
import LoadingCircle from './LoadingCircle';
import './RealTeXLoad.scss';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

type CallFunc = () => ReturnType<typeof build_tex_problema_post>;


const RealTeXLoad = (props: { id: number, api: CallFunc, callback: () => void, force_refresh?: number }) => {
  const [pdf, setPdf] = useState<string | null>(null);
  const [log, setLog] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [fetched, setFetched] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();


  const os = navigator.userAgent.match(/(android|iphone)/ig);
  const mobile = !!os;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }

  useEffect(() => {
    props.api().then(ans => {
      setPdf(ans.pdf_b64);
      setLog(atob(ans.log_b64));
      setSuccess(ans.success);
      if (ans.success) {
        props.callback();
      }
      setFetched(true);
    })
  }, [props.id, props.force_refresh])

  if (!fetched) {
    return <LoadingCircle />
  }

  if (success) {
    if (mobile) {
      return (
        // @ts-ignore
        <Document
          file={`data:application/pdf;base64,${pdf}`
          } onLoadSuccess={onDocumentLoadSuccess}
        >
          {/* @ts-ignore */}
          <Page pageNumber={pageNumber} scale={1.6} />
        </Document >
      )
    } else {
      return (
        <iframe width="100%" src={`data:application/pdf;base64,${pdf}#zoom=140%&toolbar=0&navpanes=0`} />
      )
    }
  }

  return (
    <div>
      <span>Compilazione fallita, ecco il log:</span>
      <pre style={{
        overflow: "auto",
        maxHeight: '400px',
        maxWidth: "100%",
      }}>{log}</pre>
    </div >
  )
}

export default RealTeXLoad;
