import React, { useEffect } from 'react';

import { MdMath } from '../components';
import { login_url } from '../globals'
import { useLogin } from '../reducers';


const Costanti = () => {
  const login = useLogin();
  useEffect(() => {
    document.title = "Costanti - Owlitrack";
  }, [])
  if (!login.logged_in) {
    login_url()
  }
  return (
    <div className="container table-responsive">
      <h1 className="page-header">{"Costanti fisiche primarie"}</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Costante</th>
            <th>Simbolo</th>
            <th>Valore</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Velocità della luce nel vuoto</td>
            <td><MdMath source={"$c$"} /></td>
            <td><MdMath source={"$\\num{2.99792458e8}\\,\\si{\\meter\\,\\second^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Carica elementare</td>
            <td><MdMath source={"$e$"} /></td>
            <td><MdMath source={"$\\num{1.602176634e-19}\\,\\si{\\coulomb}$"} /></td>
          </tr>
          <tr>
            <td>Costante di Planck</td>
            <td><MdMath source={"$h$"} /></td>
            <td><MdMath source={"$\\num{6.62607015e-34}\\,\\si{\\joule\\,\\second}$"} /></td>
          </tr>
          <tr>
            <td>Costante di Boltzmann</td>
            <td><MdMath source={"$k_B$"} /></td>
            <td><MdMath source={"$\\num{1.380649e-23}\\,\\si{\\joule\\,\\kelvin^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Numero di Avogadro</td>
            <td><MdMath source={"$N_A$"} /></td>
            <td><MdMath source={"$\\num{6.02214076e23}\\,\\si{\\mole^{-1}}$"} /></td>
          </tr>
        </tbody>
      </table>
      <h1>Altre costanti fisiche <MdMath source={"${}^*$"} /></h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th >Costante</th>
            <th >Simbolo</th>
            <th >Valore</th>
            <th>Valore alternativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Costante di gravitazione</td>
            <td><MdMath source={"$G$"} /></td>
            <td><MdMath source={"$\\num{6.674e-11}\\,\\si{\\meter^3\\,\\kg^{-1}\\,\\second^{-2}}$"} /></td>
          </tr>
          <tr>
            <td>Permeabilità magnetica del vuoto</td>
            <td><MdMath source={"$\\mu_0$"} /></td>
            <td><MdMath source={"$4\\pi\\cdot \\num{1e-7}\\,\\si{\\newton\\,\\ampere^{-2}}$"} /></td>
          </tr>
          <tr>
            <td>Costante dielettrica del vuoto: <MdMath source={"$1/(\\mu_0 c^2)$"} /></td>
            <td><MdMath source={"$\\epsilon_0$"} /></td>
            <td><MdMath source={"$\\num{8.8542e-12}\\,\\si{\\farad\\,\\meter^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Costante elettrostatica: <MdMath source={"$1/(4\\pi \\epsilon_0)$"} /></td>
            <td><MdMath source={"$k_{es}$"} /></td>
            <td><MdMath source={"$\\num{8.9876e+9}\\,\\si{\\farad^{-1}\\,\\meter}$"} /></td>
          </tr>
          <tr>
            <td>Costante di Faraday: <MdMath source={"$N_A e$"} /></td>
            <td><MdMath source={"$F$"} /></td>
            <td><MdMath source={"$\\num{9.6485e4}\\,\\si{\\coulomb\\,\\mole^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Costante di Stefan-Boltzmann</td>
            <td><MdMath source={"$\\sigma$"} /></td>
            <td><MdMath source={"$\\num{5.6704e-8}\\,\\si{\\watt\\,\\meter^{-2}\\kelvin^{-4}}$"} /></td>
          </tr>
          <tr>
            <td>Raggio di Bohr</td>
            <td><MdMath source={"$a_0$"} /></td>
            <td><MdMath source={"$\\num{0.52917772e-10}\\,\\si{\\meter}$"} /></td>
          </tr>
          <tr>
            <td>Massa del protone</td>
            <td><MdMath source={"$m_p$"} /></td>
            <td><MdMath source={"$\\num{1.67262e-27}\\,\\si{\\kilogram}$"} /></td>
            <td><MdMath source={"$\\num{938.27}\\,\\si{\\mega\\electronvolt}/c^2$"} /></td>
          </tr>
          <tr>
            <td>Massa del neutrone</td>
            <td><MdMath source={"$m_n$"} /></td>
            <td><MdMath source={"$\\num{1.67493e-27}\\,\\si{\\kilogram}$"} /></td>
            <td><MdMath source={"$\\num{939.55}\\,\\si{\\mega\\electronvolt}/c^2$"} /></td>
          </tr>
          <tr>
            <td>Massa dell’elettrone</td>
            <td><MdMath source={"$m_e$"} /></td>
            <td><MdMath source={"$\\num{9.1094e-31}\\,\\si{\\kilogram}$"} /></td>
            <td><MdMath source={"$\\num{511.00}\\,\\si{\\kilo\\electronvolt}/c^2$"} /></td>
          </tr>
          <tr>
            <td>Unità di massa atomica</td>
            <td><MdMath source={"$u$"} /></td>
            <td><MdMath source={"$\\num{1.66054e-27}\\,\\si{\\kilogram}$"} /></td>
          </tr>
          <tr>
            <td>Costante universale dei gas</td>
            <td><MdMath source={"$R$"} /></td>
            <td><MdMath source={"$\\num{8.31446}\\,\\si{\\joule\\,\\mole^{-1}\\,\\kelvin^{-1}}$"} /></td>
          </tr>
        </tbody>
      </table>

      <h1>Dati che possono essere necessari <MdMath source={"${}^*$"} /></h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th >Costante</th>
            <th >Simbolo</th>
            <th >Valore</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Accelerazione di gravità al suolo</td>
            <td><MdMath source={"$g$"} /></td>
            <td><MdMath source={"$\\num{9.80665}\\,\\si{\\meter\\,\\second^{-2}}$"} /></td>
          </tr>
          <tr>
            <td>Massa della Terra</td>
            <td><MdMath source={"$M_\\oplus$"} /></td>
            <td><MdMath source={"$\\num{5.972e24}\\,\\si{\\kilogram}$"} /></td>
          </tr>
          <tr>
            <td>Massa del Sole</td>
            <td><MdMath source={"$M_\\odot$"} /></td>
            <td><MdMath source={"$\\num{1.988e30}\\,\\si{\\kilogram}$"} /></td>
          </tr>
          <tr>
            <td>Distanza media Terra-Sole</td>
            <td><MdMath source={"$\\mathrm{UA}$"} /></td>
            <td><MdMath source={"$\\num{149.6e9}\\,\\si{\\meter}$"} /></td>
          </tr>
          <tr>
            <td>Raggio terrestre</td>
            <td><MdMath source={"$R_\\oplus$"} /></td>
            <td><MdMath source={"$\\num{6.375e6}\\,\\si{\\meter}$"} /></td>
          </tr>
          <tr>
            <td>Raggio del Sole</td>
            <td><MdMath source={"$R_\\odot$"} /></td>
            <td><MdMath source={"$\\num{6.957e8}\\,\\si{\\meter}$"} /></td>
          </tr>
          <tr>
            <td>Pressione atmosferica standard</td>
            <td><MdMath source={"$p_0$"} /></td>
            <td><MdMath source={"$\\num{1.01325e5}\\,\\si{\\pascal}$"} /></td>
          </tr>
          <tr>
            <td>Temperatura standard di fusione dell’acqua (<MdMath source={"$\\num{0}\\,\\si{\\celsius}$"} />)</td>
            <td><MdMath source={"$T_0$"} /></td>
            <td><MdMath source={"$\\num{273.15}\\,\\si{\\kelvin}$"} /></td>
          </tr>
          <tr>
            <td>Densità dell'acqua (a <MdMath source={"$\\num{4}\\,\\si{\\celsius}$"} />)<MdMath source={"${}^\\dagger$"} /></td>
            <td><MdMath source={"$\\rho_{\\mathrm{a}}$"} /></td>
            <td><MdMath source={"$\\num{1.000e+3}\\,\\si{\\kilogram\,\\metre^{-3}}$"} /></td>
          </tr>
          <tr>
            <td>Calore specifico dell'acqua (a <MdMath source={"$\\num{20}\\,\\si{\\celsius}$"} />)<MdMath source={"${}^\\dagger$"} /></td>
            <td><MdMath source={"$c_{\\mathrm{a}}$"} /></td>
            <td><MdMath source={"$\\num{4.182e+3}\\,\\si{\\joule\\,\\kilogram^{-1}\\,\\kelvin^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Calore di vaporizzazione dell'acqua (a <MdMath source={"$\\num{100}\\,\\si{\\celsius}$"} />)<MdMath source={"${}^\\dagger$"} /></td>
            <td><MdMath source={"$\\lambda_{\\mathrm{v}}$"} /></td>
            <td><MdMath source={"$\\num{2.257e+6}\\,\\si{\\joule\\,\\kilogram^{-1}}$"} /></td>
          </tr>
          <tr>
            <td>Densità del ghiaccio (a <MdMath source={"$\\num{0}\\,\\si{\\celsius}$"} />)<MdMath source={"${}^\\dagger$"} /></td>
            <td><MdMath source={"$\\rho_{\\mathrm{g}}$"} /></td>
            <td><MdMath source={"$\\num{0.917e+3}\\,\\si{\\kilogram\,\\metre^{-3}}$"} /></td>
          </tr>
          <tr>
            <td>Calore di fusione del ghiaccio</td>
            <td><MdMath source={"$\\lambda_{\\mathrm{f}}$"} /></td>
            <td><MdMath source={"$\\num{3.344e+5}\\,\\si{\\joule\\,\\kilogram^{-1}}$"} /></td>
          </tr>
        </tbody>
      </table>
    </div >
  );
}



export default Costanti;
