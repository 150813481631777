import "core-js/stable";
import "regenerator-runtime/runtime";

import {
  get_single_json, get_paginated_json, post_page, plain_object
} from 'django-rest-react';

import {
  ThreadDettaglio, ThreadLista, Risposta, Linter, FiltroProblemaThread,
} from './types';

export * from './webpush';
export * from './cache';
export * from './websocket';

export {
  put_assegna_problema as assegna_problemi, post_voto_bellezza as post_bellezza, post_voto_difficolta as post_difficolta,
  post_avatar as update_avatar, post_avatar_serio as update_avatar_serio, post_soluzione_con_risposta as crea_soluzione,
  patch_soluzione_con_risposta as modifica_soluzione, delete_soluzione_con_risposta as delete_soluzione,
  post_flag_smistatore as post_flags, patch_flag_smistatore as patch_flags, get_partecipante_contributor as get_contributors,
  post_build_shortlist_detail_build_tex as build_tex, get_bozza_problema, post_bozza_problema as create_bozza,
  get_bozza_problema_detail, delete_bozza_problema as delete_bozza, patch_bozza_problema as patch_bozza,
  get_commento_detail_2 as get_commento_shortlist_detail, patch_shortlist as patch_versione_shortlist,
  post_thread_shortlist as post_nuova_shortlist, get_whoami, post_thread_problem_generic_con_risposta as post_new_problema,
  patch_thread_problem_generic_con_risposta as patch_new_problema, patch_commento as patch_commento,
  patch_commento_2 as patch_commento_shortlist, post_commento_2 as post_new_commento_shortlist, post_commento as post_new_commento,
  get_allegato_detail as get_allegato, post_allegato as upload_allegato, post_risposta_con_risposta as post_risposta,
  get_commento_detail, patch_whoami as update_profile, get_risposta_con_risposta as get_mie_risposte, get_allegato as get_allegati,
  get_voto_bellezza as get_bellezza, get_voto_difficolta as get_difficolta, get_problema_versionato_feed as get_feed_modifiche_problemi,
  get_commento_versionato_feed as get_feed_modifiche_commenti, get_assegnazione_problema as get_feed_assegnazione,
  get_assegnazione_problema_evento as get_feed_assegnazione_evento, get_citazione_thread as get_citazioni_thread,
  get_commento_2 as get_commenti_shortlist, get_commento as get_commento_list, get_bozza_problema_detail as get_bozza,
  get_bozza_problema as get_bozze,
} from './auto-apis'

import { post_tex_compilation_response_detail_build_tex_soluzione, post_tex_compilation_response_detail_build_tex_problema, get_tex_compilation_response_detail_build_tex_problema, get_tex_compilation_response_detail_build_tex_soluzione } from './auto-apis'
// @ts-ignore
export const build_tex_problema_post = (pk: number, form: { override?: string }) => post_tex_compilation_response_detail_build_tex_problema(pk, form);
export const build_tex_problema_get = (pk: number) => get_tex_compilation_response_detail_build_tex_problema(pk);
// @ts-ignore
export const build_tex_soluzione_post = (pk: number, form: { override?: string }) => post_tex_compilation_response_detail_build_tex_soluzione(pk, form);
export const build_tex_soluzione_get = (pk: number) => get_tex_compilation_response_detail_build_tex_soluzione(pk);

interface FiltroRisposte {
  partecipante?: number
  problema?: number
  data__gte?: string
  data__lte?: string
}



export const get_problema_thread = async (pk: number) => get_single_json<ThreadDettaglio>(`/api/problems/problemi/${pk}/`)
export const get_problema_thread_list = async (filtro?: FiltroProblemaThread) => get_paginated_json<ThreadLista>('/api/problems/problemi/', filtro as unknown as plain_object)
export const post_letto_thread = async (id: number) => post_page(`/api/problems/problemi/${id}/add_visualization/`, {})

export const lint_text = async (str: string) => post_page<Linter[]>("/api/problems/lint/", { "original": str })
export const search_problemi_similar = async (pk: number) => get_single_json<[number, number][]>("/api/problems/search/", { "similar": pk })
export const search_problemi_string = async (val: string) => get_single_json<[number, number][]>("/api/problems/search/", { "search": val })
export const get_risposte = async (filtro?: FiltroRisposte) => get_paginated_json<Risposta>("/api/problems/problemi/risposta/", filtro as plain_object)
