import React from 'react';
import { Link } from "react-router-dom";

import { GOOGLE_URL } from '../globals'
import { useLogin, useOnline } from '../reducers';


const Navbar = () => {
  let login_block;
  const login = useLogin()
  const online = useOnline();
  const { user } = login.user;
  if (login.logged_in) {
    login_block = (
      <>
        <Link className="dropdown-item" to={`/accounts/profile/`}>Il tuo profilo</Link>
        <a className="dropdown-item" href="/accounts/logout/">Logout</a>
        {(user.is_staff || user.is_superuser) &&
          <a className="dropdown-item" href="/admin/">Django admin</a>
        }
      </>
    );
  } else {
    login_block = (
      <>
        <a className="dropdown-item" href={GOOGLE_URL}>Login con Google</a>
      </>
    );
  }
  let log_name = login.logged_in ? user.first_name + " " + user.last_name : "Login";
  const nav_color = online.online ? "bg-primary" : "bg-wet-asphalt";
  const extra_msg = online.online ? "" : "(offline)";
  return (
    <nav className={`navbar navbar-nav navbar-dark ${nav_color} navbar-expand-lg px-3`}>
      <div className="container" >
        <Link to={`/`} className="navbar-brand">
          <img src={`/static/favicon.ico`}
            alt="Buona Fortuna." width="33"
            className="mx-1"
          />
          Owlitrack {extra_msg}
        </Link>
        <button
          className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={`/about/`} className="nav-link">Guida</Link>
            </li>
            <li className="nav-item">
              <Link to="/guidelines/" className="nav-link">Stile</Link>
            </li>
            <li className="nav-item">
              <Link to={`/costanti/`} className="nav-link">Costanti numeriche</Link>
            </li>
            <li className="nav-item">
              <Link to={`/changelog/`} className="nav-link">Changelog</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown ml-auto">
              <a className="nav-link dropdown-toggle" href="#"
                id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {log_name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {login_block}
              </div>
            </li>
          </ul>
        </div>
      </div >
    </nav>
  );
}
export default Navbar;
