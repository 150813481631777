import { SET_ONLINE, SET_OFFLINE } from '../actions/action-types';
import { OnlineAction } from '../actions/online';

export interface OnlineState {
  online: boolean
}

export function onlineReducer(state: OnlineState = { online: true }, action: OnlineAction) {
  if (action.type === SET_ONLINE && !state.online) {
    return Object.assign({}, state, { online: true, })
  } else if (action.type === SET_OFFLINE && state.online) {
    return Object.assign({}, state, { online: false, })
  } else {
    return state;
  }
}
