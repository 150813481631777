import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, FormikErrors } from 'formik';
import { Link, useParams } from 'react-router-dom';

import {
  FormGroupBootstrap, ErrorBlockGeneric, Spoiler,
  LoadingCircle, ConfirmedButton, MdMath
} from '../components';
import {
  get_risposte, get_mie_risposte, post_risposta, get_problema_thread,
} from '../api';
import { APIError, handle_drf_form_error } from 'django-rest-react';
import {
  nice_date,
  displayPartecipante, displayRisposta
} from '../utils';
import { Problema, Soluzione } from '../api/types';
import { canSeeRisposte, DECIMAL_PLACES, login_url } from '../globals';
import { useLogin, usePartecipante } from '../reducers'


type RispostaForm = Parameters<typeof post_risposta>[0];
type FormValues = Omit<RispostaForm, "risposta"> & { risposta: number }
type Risposta = Awaited<ReturnType<typeof get_risposte>>[0]


const RispondiProblema = (props: {}) => {
  const login = useLogin();
  const partecipanti = usePartecipante(props);
  const [mie_risposte, setMieRisposte] = useState<Risposta[]>([]);
  const [problema, setProblema] = useState<Problema | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [risposte, setRisposte] = useState<Risposta[]>([]);
  const params = useParams<{ pk: string }>();
  const pk = parseInt(params.pk as string);

  const fetch_data = () => {
    get_problema_thread(pk).then(thread => {
      const pk_domande = thread.problema.soluzioni.map(item => item.id);
      get_risposte({ problema: thread.problema.id }).then(risposte => {
        setProblema(thread.problema);
        setRisposte(risposte.filter(item => (pk_domande.includes(item.domanda))))
      });
      get_mie_risposte({ problema: thread.problema.id }).then(mie_risposte =>
        setMieRisposte(mie_risposte.filter(item => pk_domande.includes(item.domanda)))
      );
    });
  }

  useEffect(() => {
    document.title = "Rispondi problema - Owlitrack";
    fetch_data();
  }, [])

  if (!login.logged_in) {
    login_url()
  }

  let risposte_vecchie;
  if (mie_risposte.length > 0) {
    risposte_vecchie = (
      <ul>
        {mie_risposte
          .map((item, key) => {
            const pill = item.corretto ?
              <span className="badge badge-success">Corretto</span> :
              <span className="badge badge-danger">Sbagliato</span>;
            let label = "";
            if (problema) {
              const sol = problema.soluzioni.find(i => (i.id == item.domanda));
              label = sol ? sol.label : "";
            }
            return <li key={key}>{nice_date(new Date(item.data))}: {pill} {label} {displayRisposta(item)}</li>
          }
          )}
      </ul>
    );
  } else {
    risposte_vecchie = (
      <p>Non hai ancora risposto a questo problema.</p>
    )
  }
  if (!problema) {
    return <LoadingCircle />
  }

  let may_i_hide_json = true;

  if (error) {
    if (Object.keys(error).filter(function (string) { return string != "domanda" && string != "risposta" && string != "problema" }).length > 0) {
      may_i_hide_json = false;
      // Se state.error contiene solo i campi "domanda", "risposta" e/o "problema" (la cui
      // visualizzazione è gestita dal form stesso), non mostrarlo nel blocco errore
    }
  }
  const { soluzioni } = problema;
  const sol_iniziale = soluzioni.length == 1 ? soluzioni[0].id : null;

  return (
    <div className="container">
      <h1 className="page-header">Rispondi al problema</h1>
      <ErrorBlockGeneric error={error} hidejson={may_i_hide_json} />
      <h3>Le risposte che hai già dato</h3>
      {risposte_vecchie}
      <Formik
        initialValues={{
          risposta: 0, risposta_exp: 0,
          domanda: sol_iniziale as number,
        }}
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => post_risposta({
          ...values,
          risposta: values.risposta.toFixed(Math.round(-Math.log10(DECIMAL_PLACES))),
        })
          .then(fetch_data)
          .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
          .finally(() => actions.setSubmitting(false))
        }
        enableReinitialize
      >
        {({ isSubmitting, handleReset, values }) => {
          const { soluzioni } = problema;
          const domanda = soluzioni.find(item => item.id == values.domanda)
          return (
            <>
              <Form className="form needs-validation">
                <div className="row">
                  <div className="col">
                    <FormGroupBootstrap
                      name="risposta" type="number" step={DECIMAL_PLACES}
                      help_text={"Se una risposta è giusta o meno " +
                        "viene controllato vedendo se cade in una " +
                        "precisione moltiplicativa intorno alla risposta vera. " +
                        "La precisione è indicata qui sotto"
                      }
                    />
                  </div>
                  <div className="col">
                    <div className="row">
                      <p style={{ verticalAlign: 'bottom', marginTop: '15px', textAlign: 'right' }}>
                        ✕10
                      </p>
                      <div className="col-sm-3 ml-0">
                        <FormGroupBootstrap
                          name="risposta_exp" type="number" step={1}
                          displayName=" "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <FormGroupBootstrap
                  name="domanda" type="select"
                  choices={soluzioni}
                  displayChoice={(item: Soluzione) => `${item.label} [${item.udm}]`}
                />
                <div className="text-center">
                  {domanda &&
                    <>
                      <MdMath source={domanda.udm || ""} />
                      <span>La precisione richiesta è {domanda.precisione}</span>
                    </>
                  }
                </div>
                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  <button type="submit" disabled={isSubmitting}
                    className="mx-1 btn btn-primary">Invia</button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <h3>Le risposte degli altri</h3>
      {
        risposte.map((item, key) => {
          const pill = item.corretto ?
            <span className="badge badge-success">Corretto</span> :
            <span className="badge badge-danger">Sbagliato</span>;
          let label = "";
          if (problema) {
            const sol = problema.soluzioni.find(i => (i.id == item.domanda));
            label = sol ? sol.label : "";
          }

          return (
            <li key={key}>{nice_date(new Date(item.data))}:
              <Link to={`/utente/${item.partecipante}/`}>
                {displayPartecipante(item.partecipante)}
              </Link> {pill} {label} {canSeeRisposte(login.user.permessi) && <Spoiler>{displayRisposta(item)}</Spoiler>}
            </li>
          );
        })
      }
    </div >
  );
}


export default RispondiProblema;
